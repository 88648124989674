import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";
// import Backend from 'i18next-http-backend';

i18n
  //   .use(Backend)
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    debug: true,
    fallbackLng: "uk",
    returnObjects: true,
    interpolation: {
      escapeValue: false,
    },
    resources: {
      en: {
        translation: {
          home: "Home",
          services: "Services",
          portfolio: "Portfolio",
          portfolioSmallTitle: "Main functionality",
          portfolioButton: "Link to the site",
          PortfolioTechnologiesTitle: "Technologies that were used",
          PortfolioSameTitle: "Same Projects",
          feedback: "Feedback",
          development: "Development",
          switcher: "EN",
          mainTitleUp: "Web development",
          mainTitleDown: "and turnkey promotion strategies",
          mainTitleText1: "Taking care of business",
          mainTitleText2: "Successful sales automation",
          mainTitleText3: "All statistics and analytics on the phone screen",
          mainBtn: "Accept the offer",
          servicesTitle: "Our Services",
          servicesType: "What we develop",
          servicesDesc:
            "We provide technological competencies and practical experience to develop effective software solutions that that meet the requirements and goals of your business.",
          reactDesc:
            "Create interactive and engaging user interfaces that will transform your website into a powerful tool for attracting and retaining customers.",
          technologies: "Technologies",
          technologiesTitle: "What we do",
          technologiesType: "Tech stack",
          techTitle1: "Building Visualization",
          techTitle2: "Creation of animations",
          techTitle3: "1000+ products",
          techTitle4: "Website of any complexity",
          techTitle5: "Base, Ground...",
          techTitle6: "Access from Everest",
          techText1: "We create visual elements of your future application.",
          techText2: "We will make everyone pay attention to you with animated interfaces.",
          techText3:
            " We optimize all the features of your future application to sell an infinite number of your products.",
          techText4: "We are ready to realize any of your fantasies.",
          techText5: "Our team will create a powerful tool for data storage.",
          techText6: "We will find the best solution for hosting your future application.",
          runTitle: "About us in two words",
          runS1T: "5+ years of experience",
          runS1D: {
            line1: "A team of professionals",
            line2: "with the necessary background",
          },
          runS2T: "50+ team members",
          runS2D: {
            line1: "The necessary people",
            line2: "are allocated for each project",
          },
          runS3T: "100% done on time",
          runS3D: {
            line1: "Time is determined",
            line2: "according to the signed contract",
          },
          runS4T: "200+ recommendations",
          runS4D: {
            line1: "Our customers",
            line2: "remain satisfied",
          },
          runS5T: "300+ completed projects",
          runS5D: {
            line1: "Thanks to the fact",
            line2: "that we are recommended to friends",
          },
          projectsTitle: "Our Projects",
          projectsType: "Portfolio",
          projectsCardDesc: "Hover over this card to unleash the power of CSS perspective",
          projectsCardBtn: "About Case",
          projectsCardBtnSeeWeb: "To website",
          projectsBtn: "See More",
          devTitle: "Stages of Development",
          stageTitle1: "STEP 1: PLANNING",
          stageTitle2: "STEP 2: DESIGN AND GRAPHICS",
          stageTitle3: "STEP 3: CODING",
          stageTitle4: "STEP 4: TESTING AND FILLING",
          stageTitle5: "STEP 5: RUN!",
          stageText1: "Project analysis, Commercial offer, Conclusion of the contract, 1st part of payment 50%",
          stageText2:
            "Design of the main page, Design of subpages, Unique graphic elements*, Block animation*, Development of adaptive design, Approval of the final design version",
          stageText3: "Layout design, Website programming, Connecting and configuring additional tools",
          stageText4:
            "Test content of the site*, Testing of the project, Domain registration*, Transferring to hosting or server*, 2nd part of the payment 50%",
          stageText5: "Startup and training, Warranty period*",
          responseTitle: "What people say about us",
          footerTitle: "We are waiting for you!",
          footerType: "Get a detailed and clear commercial offer",
          footerInputName: "Whats is your name?",
          footerInputPhone: "Phone Number",
          footerInputText: "Tell us about your project",
          footerFile: "Attach a file",
          footerBtn: "Discuss a project",
          footerCountry: "Kyiv, Ukraine",
          footerStreet: {
            line1: "St. Boychuk Mykhailo,",
            line2: "bldg. 41-B, office 2018",
          },
          footerEl: "Email:",
          modalTitle: "Please leave your messege",
          modalDesc: "We will contact you as soon as possible to discuss your project ideas",
          card1Desc:
            "Create interactive and engaging user interfaces that will transform your website into a powerful tool for attracting and retaining customers.",
          contractTitle: "Explore our standard service agreement",
          contractDesc: "Simply click the button to review it",
          contractBtn: "View Agreement",
          answers: "Frequently asked questions",
          answersTitle: "Answers to frequently questions",
          teamTitle: "Our team",
          teamSmallTitle: "Meet us",
          tgMessage1: "Quick help from an expert",
          tgMessage2: "Text to us!)",
          inputDescription: "The subject of your project",

          actionTitle: "Action",
          actionType: "Hurry up and order",
        },
      },
      uk: {
        translation: {
          home: "Головна",
          services: "Послуги",
          portfolio: "Портфоліо",
          portfolioSmallTitle: "Основний функціонал",
          portfolioButton: "Посилання на сайт",
          PortfolioTechnologiesTitle: "Технології які були використані",
          PortfolioSameTitle: "Схожі проекти",
          feedback: "Контакти",
          development: "Розробка",
          switcher: "UA",
          mainTitleUp: "Створення веб-сайтів та",
          mainTitleDown: "стратегії просування під ключ",
          mainTitleText1: "Турбота про бізнес",
          mainTitleText2: "Успішна автоматизація продажів",
          mainTitleText3: "Вся статистика та аналітика на екрані смартфону",
          mainBtn: "Отримати пропозицію",
          servicesTitle: "Наші послуги",
          servicesType: "Що ми розробляємо",
          servicesDesc:
            "Ми надаємо технологічні компетенції та практичний досвід для розробки ефективних програмних рішень, які відповідають вимогам і цілям вашого бізнесу.",
          reactDesc:
            "Створюйте інтерактивні та привабливі інтерфейси користувача, які перетворять ваш веб-сайт на потужний інструмент для залучення та утримання клієнтів.",
          technologies: "Технології",
          technologiesTitle: "Чим ми орудуємо",
          technologiesType: "Стек технологій",
          techTitle1: "Побудова візуалу",
          techTitle2: "Створення анімацій",
          techTitle3: "1000+ товарів",
          techTitle4: "Сайт будь-якої складності",
          techTitle5: "База, Грунт...",
          techTitle6: "Доступ з Евересту",
          techText1: "Створюємо візуальні елементи вашого майбутнього застосунку.",
          techText2: "Ми змусимо всіх привернути увагу до вас завдяки анімованим інтерфейсам.",
          techText3:
            " Оптимізуємо всі можливості вашого майбутнього застосунку для продажу нескінченої кількості вашої продукції.",
          techText4: "Ми готові реалізувати будь-які ваші фантазії.",
          techText5: "Наша команда створить потужний інструмент для зберігання данних.",
          techText6: "Ми знайдемо накраще рішення для хостингу вашого майбутнього застосунку.",
          runTitle: "Про нас у двох словах",
          runS1T: "5+ років досвіду",
          runS1D: {
            line1: "Команда професіоналів",
            line2: "з потрібним бекграундом",
          },
          runS2T: "50+ членів команди",
          runS2D: {
            line1: "Під кожен проєкт",
            line2: "виділяються необхідні люди",
          },
          runS3T: "100% вклались в час",
          runS3D: {
            line1: "Терміни визначаються",
            line2: "згідно підписаного договору",
          },
          runS4T: "200+ рекомендацій",
          runS4D: {
            line1: "Наші клієнти",
            line2: "залишаються задоволеними",
          },
          runS5T: "300+ зроблено проєктів",
          runS5D: {
            line1: "Завдяки тому,",
            line2: "що нас радять знайомим",
          },
          projectsTitle: "Наші Проєкти",
          projectsType: "Портфоліо",
          projectsCardDesc: "Наведіть курсор на цю картку, щоб розкрити можливості CSS перспективи",
          projectsCardBtn: "Про Кейс",
          projectsCardBtnSeeWeb: "На сайт",
          projectsBtn: "Побачити більше",
          devTitle: "Етапи розробки",
          stageTitle1: "КРОК 1: ПЛАНУВАННЯ",
          stageTitle2: "КРОК 2: ДИЗАЙН І ГРАФІКА",
          stageTitle3: "КРОК 3: КОДУВАННЯ",
          stageTitle4: "КРОК 4: ТЕСТУВАННЯ ТА ЗАПОВНЕННЯ",
          stageTitle5: "КРОК 5: ПРОБІЖКА",
          stageText1: "Аналіз проєкту, Комерційна пропозиція, Укладання договору, 1 частина платежу 50%",
          stageText2:
            "Дизайн головної сторінки, Дизайн підсторінок, Унікальні грифічні елементи*, Анімація*, Розробка адаптивного дизайну, Затвердження остаточного варіанту проєкту",
          stageText3: "Дизайн макета, Програмування сайту, Підключення та налаштування додаткових інструментів",
          stageText4:
            "Тестовий контент сайту*, Тестування проєкту, Реєстрація домену*, Перенесення на хостинг або сервер*, 2 частина платежу 50%,",
          stageText5: "Запуск і навчання, Гарантійний термін*",
          responseTitle: "Що про нас говорять люди",
          footerTitle: "Ми чекаємо на вас!",
          footerType: "Отримайте детальну та зрозумілу комерційну пропозицію",
          footerInputName: "Як вас звуть?",
          footerInputPhone: "Номер телефону",
          footerInputText: "Розкажіть про свій проєкт",
          footerFile: "Прикріпити файл",
          footerBtn: "Обговорити проєкт",
          footerCountry: "Київ, Україна",
          footerStreet: {
            line1: "вул. Бойчука Михайла,",
            line2: "буд. 41-Б, оф. 2018",
          },
          footerEl: "Електронна пошта:",
          modalTitle: "Будь ласка, залиште своє повідомлення",
          modalDesc: "Ми зв'яжемося з вами якнайшвидше для обговорення ідей до вашого проєкту",
          card1Desc:
            "Створюємо інтерактивні та захоплюючі інтерфейси користувача, які перетворять ваш веб-сайт на потужний інструмент для приваблення та утримання клієнтів.",
          contractTitle: "Ознайомтесь з нашим типовим договором на послуги",
          contractDesc: "Просто натисніть кнопку, щоб ознайомитися з ним",
          contractBtn: "Переглянути договір",
          answers: "Часті питання",
          answersTitle: "Відповіді на часті питання",
          teamTitle: "Наша команда",
          teamSmallTitle: "Зустрічайте нас",
          tgMessage1: "Швидка консультація експерта",
          tgMessage2: "Пишіть нам!)",
          inputDescription: "Тематика вашого проекта",

          actionTitle: "Акція",
          actionType: "Встигни замовити",
        },
      },
    },
  });

export default i18n;
