import "../styles/Services.scss";
import Layout from "./Layout";
import { useState } from "react";
import { Modal } from "./ui/Modal";
import { useTranslation } from "react-i18next";

import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/scrollbar";

const Services = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("Лендінг");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <section className="Services" id="services">
      {showModal && (
        <div className="modalContent">
          <Modal toggleModal={toggleModal} typeFromBody={type} />
          <div className="modalBg" onClick={toggleModal}></div>
        </div>
      )}
      <Layout title={t("servicesTitle")} type={t("servicesType")}>
        <div className="ServicesCardsBody">
          <div className="ServicesCard">
            <div className="ServicesCardText">
              <h1>{i18n.language === "en" ? "Landing" : "Лендінги"}</h1>
              <p>
                {i18n.language === "en"
                  ? "A landing page is a one-page website for a marketing or advertising campaign aimed at attracting visitors and converting them into customers. "
                  : "Лендінг - це односторінковий сайт для маркетингової або рекламної кампанії, яка має на меті залучення відвідувачів і перетворення їх у клієнтів. "}
              </p>
              <br />
              <p className="ServicesCardTextSmall">
                {i18n.language === "en"
                  ? "(The price of development includes design, layout, functionality, and a sales call center bot for collecting orders)"
                  : `(В ціну розробки входить дизайн, верстка, функціонал, ТГ-бот для збору замовлень)`}
              </p>
            </div>
            <div className="LowSection">
              <div className="Price">
                <h3>
                  {i18n.language === "en" ? "from" : "від"}
                  {/* <div className="oldMoney">1500$</div>  */}
                  <p>900$</p>
                </h3>
                {/* <p style={{ fontSize: "12px", color: "#ff255c", paddingTop: "5px" }}>
                  {i18n.language === "en"
                    ? "The promotion is valid throughout september!"
                    : "Акція діє впродовж вересня!"}
                </p> */}
              </div>
              <button
                className="section_btn_get"
                onClick={() => {
                  toggleModal();
                  setType("Лендінг");
                }}
              >
                {i18n.language === "en" ? "Order" : "Замовити"}
              </button>
            </div>
          </div>
          <div className="ServicesCard">
            <div className="ServicesCardText">
              <h1> {i18n.language === "en" ? "Corporative websites" : "Корпоративні сайти"}</h1>
              <p>
                {i18n.language === "en"
                  ? "A corporate website is a web resource used to inform customers, partners, and the public about the company's activities, products, services, mission, values, and other aspects. "
                  : "Корпоративний сайт — це веб-ресурс, який використовується для інформування клієнтів, партнерів і громадськості про діяльність компанії, її продукти, послуги, місію, цінності та інші аспекти."}
              </p>
              <br />
              <p className="ServicesCardTextSmall">
                {i18n.language === "en"
                  ? "(The price of development includes design, layout, functionality, a sales force bot for collecting orders, creating an advertising account, and SEO optimization)"
                  : `( В ціну розробки входить дизайн, верстка, функціонал, ТГ-бот для збору замовлень, створення рекламного кабінету, СЕО-оптимізація)`}
              </p>
            </div>
            <div className="LowSection">
              <div className="Price">
                <h3>
                  {i18n.language === "en" ? "from" : "від"}
                  <p>2600$</p>
                </h3>
              </div>
              <button
                className="section_btn_get"
                onClick={() => {
                  toggleModal();
                  setType("Корпоративний сайт");
                }}
              >
                {i18n.language === "en" ? "Order" : "Замовити"}
              </button>
            </div>
          </div>
          <div className="ServicesCard">
            <div className="ServicesCardText">
              <h1>{i18n.language === "en" ? "Online-Store" : "Інтернет-магазини"}</h1>
              <p>
                {i18n.language === "en"
                  ? "An online store is a place to sell your company's products. In today's digital world, it is the best solution to increase sales and credibility."
                  : `Інтернет магазин - це місце для продажу продукції компанії. В сучасному Digital світі це найкраще рішення для збільшення продажів та довіри до себе.`}
              </p>
              <br />
              <p className="ServicesCardTextSmall">
                {i18n.language === "en"
                  ? "(The development price includes design, layout, functionality, admin panel, payment system)"
                  : `(В ціну розробки входить дизайн, верстка, функціонал, Адмін панель, Платіжна система)`}
              </p>
            </div>
            <div className="LowSection">
              <div className="Price">
                <h3>
                  {i18n.language === "en" ? "from" : "від"} <p>5000$</p>
                </h3>
              </div>
              <button
                className="section_btn_get"
                onClick={() => {
                  toggleModal();
                  setType("Інтернет-магазин");
                }}
              >
                {i18n.language === "en" ? "Order" : "Замовити"}
              </button>
            </div>
          </div>
          <div className="ServicesCard">
            <div className="ServicesCardText">
              <h1> {i18n.language === "en" ? "Mobile Applications" : "Мобільні Застосунки"}</h1>

              <p>
                {i18n.language === "en"
                  ? "The mobile app is a comprehensive solution for any task. It is the best solution for delegating about 40% of the business owner's routine actions."
                  : "Мобільний застосунок - це комплексне рішення для будь-яких задач. Найкраще рішення для делегування близько 40% рутинних дій власника бізнесу."}
              </p>
            </div>
            <div className="LowSection">
              <div className="Price">
                <h3>
                  {i18n.language === "en" ? "from" : "від"} <p>10000$</p>
                </h3>
              </div>
              <button
                className="section_btn_get"
                onClick={() => {
                  toggleModal();
                  setType("Мобільний застосунок");
                }}
              >
                {i18n.language === "en" ? "Order" : "Замовити"}
              </button>
            </div>
          </div>
        </div>
      </Layout>
    </section>
  );
};

export default Services;
