import "../styles/Team.scss";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Layout from "./Layout";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import { useEffect, useState } from "react";

const Team = () => {
  const { t, i18n } = useTranslation();
  const [spaceBetweenSlides, setSpaceBetweenSlides] = useState(80);
  const [centeredSlides, setCenteredSlides] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth >= 1600) {
        setSpaceBetweenSlides(80);
        setCenteredSlides(false);
      }
      if (window.innerWidth <= 1600 && window.innerWidth >= 950) {
        setSpaceBetweenSlides(40);
        setCenteredSlides(false);
      }
      if (window.innerWidth <= 950) {
        setSpaceBetweenSlides(20);
        setCenteredSlides(true);
      }
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    let width = window.innerWidth;
    if (width >= 1600) {
      setSpaceBetweenSlides(80);
      setCenteredSlides(false);
    }
    if (width <= 1600 && width >= 950) {
      setSpaceBetweenSlides(40);
      setCenteredSlides(false);
    }
    if (width <= 950) {
      setSpaceBetweenSlides(20);
      setCenteredSlides(true);
    }
  }, []);

  return (
    <section className="team">
      <Layout title={t("teamTitle")} type={t("teamSmallTitle")}>
        <div className="teamBody">
          <Swiper
            slidesPerView={"auto"}
            spaceBetween={spaceBetweenSlides}
            autoplay={false}
            className="teamSwiper"
            centeredSlides={centeredSlides}
            loop={true}
            modules={[Autoplay]}
          >
            {teamMembers.map((el, index) => (
              <SwiperSlide className="TeamSlide _grad1">
                <span></span>
                <div className="my-content">
                  <div className="my-content__img">
                    <img src={process.env.PUBLIC_URL + el.picture} alt={el.fullNameEn} />
                  </div>
                  <div className="my-content__info">
                    <h3>{el.position}</h3>
                    <p>{i18n.language === "en" ? el.fullNameEn : el.fullNameUa}</p>
                  </div>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </Layout>
    </section>
  );
};

export default Team;

const teamMembers = [
  {
    id: 0,
    picture: "/team/team5.webp",
    position: "CEO",
    fullNameEn: "Mykyta",
    fullNameUa: "Микита",
  },
  {
    id: 1,
    picture: "/team/team1.webp",
    position: "CTO",
    fullNameEn: "Dmytro",
    fullNameUa: "Дмитро",
  },
  {
    id: 2,
    picture: "/team/team2.webp",
    position: "Fullstack Lead",
    fullNameEn: "Denis",
    fullNameUa: "Денис",
  },
  {
    id: 3,
    picture: "/team/team3.webp",
    position: "Head of PM",
    fullNameEn: "Daria",
    fullNameUa: "Дар'я",
  },
  {
    id: 4,
    picture: "/team/team4.webp",
    position: "Head of Sales",
    fullNameEn: "Dmytro",
    fullNameUa: "Дмитро",
  },
];
