import "../styles/App.scss";
import Main from "./Main";
import Services from "./Services";
import Portfolio from "./Portfolio";
import TimeLine from "./TimeLine";
import RunningLine from "./RunningLine";
// import Response from "./Response";
// import Technologies from "./Technologies";
import Stages from "./Stages";
import Team from "./Team";
import Action from "./Action";

const Home = () => {
  return (
    <>
      <Main />
      <Action />
      <Services />
      <Portfolio />
      <Team />
      <RunningLine />
      <Stages />
      {/* <Technologies /> */}
      {/* <Response /> */}
      <TimeLine />
    </>
  );
};

export default Home;
