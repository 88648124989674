import { useTranslation } from "react-i18next";
import "../styles/Action.scss";
import Layout from "./Layout";
import { useState } from "react";
import { Modal } from "./ui/Modal";

const Action = () => {
  const { t, i18n } = useTranslation();
  const [showModal, setShowModal] = useState(false);
  const [type, setType] = useState("Лендінг");

  const toggleModal = () => {
    setShowModal(!showModal);
  };

  return (
    <div className="Action">
      {showModal && (
        <div className="modalContent">
          <Modal toggleModal={toggleModal} typeFromBody={type} />
          <div className="modalBg" onClick={toggleModal}></div>
        </div>
      )}
      <Layout title={t("actionTitle")} type={t("actionType")}>
        <div className="card">
          <div className="bg">
            <h1>{i18n.language === "en" ? "New Year Discounts" : "Новорічні Знижки"}</h1>
            {/* <h1>Новорічні Знижки</h1> */}
            <p>
              {i18n.language === "en"
                ? "Order your website by January 20 and get "
                : "Встигніть залишити заявку до 20-го січня, та отримайте "}
              <span>{i18n.language === "en" ? "Holiday Discounts" : "Святкові Знижки! "}</span>
              {i18n.language === "en"
                ? " for any of your decisions! Details of the promotion in the commercial offer"
                : " Деталі акції в комерційній пропозиції "}
            </p>
            <div className="bottomSection">
              <button
                onClick={() => {
                  toggleModal();
                  setType("Лендінг");
                }}
              >
                {i18n.language === "en" ? "Order with a discount" : "Отримати Знижку"}
              </button>
              <img src={process.env.PUBLIC_URL + "Snowmen.webp"} alt="" />
            </div>
          </div>
          <div className="snow"></div>
          {/* <div className="blob"></div> */}
        </div>
      </Layout>
    </div>
  );
};

export default Action;
